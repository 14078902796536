import type {ISettingsStore} from "data/stores/settings/settings.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {NavigateFunction} from "react-router-dom";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";

interface IParams {
	navigate: NavigateFunction;
}

export interface ILandingController extends ViewController<IParams> {
	openRegister: () => void;
	openLogin: () => void;
	handleSecret: () => void;

	get i18n(): ILocalizationStore;
	get isSecretPassed(): boolean;
}

@injectable()
export class LandingController implements ILandingController {
	@observable private _navigate: NavigateFunction | undefined;
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isSecretPassed(): boolean {
		return SecretGateController.IS_SECRET_PASSED;
	}

	public openRegister = () => {
		this._modalsStore.showModal(ModalType.REGISTER);
	};

	public openLogin = () => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	public handleSecret = () => {
		SecretGateController.HANDLE_SECRET_CLICK();
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._navigate = param.navigate;

		if (this._settingsStore.settings?.hideLanding === true) {
			this._navigate?.("/team");
		}
	}

	onChange(param: IParams): void {
		this._navigate = param.navigate;
	}
}
