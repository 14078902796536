import type {ISettingsStore} from "data/stores/settings/settings.store";
import type {IUserStore} from "data/stores/user/user.store";
import React from "react";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IRacesStore} from "data/stores/races/races.store";
import type {IHorsesStore} from "data/stores/horses/horses.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IApiResponse} from "data/services/http";
import type {ITeamStore} from "data/stores/team/team.store";
import {AxiosError} from "axios";
import {NO_TEAM_CODE} from "data/constants";
import {ModalType, TeamPageSection} from "data/enums";
import type {IEventsStore} from "data/stores/events/events.store";
import type {NavigateFunction} from "react-router-dom";

interface IParams {
	navigate: NavigateFunction;
}

export interface ITeamPageController extends ViewController<IParams> {
	setActiveTab: (event: React.SyntheticEvent<HTMLButtonElement>) => void;

	getIsTabActive: (tab: TeamPageSection) => boolean;

	getIsTabActiveClass: (tab: TeamPageSection) => string;

	get isLoading(): boolean;
}

@injectable()
export class TeamPageController implements ITeamPageController {
	@observable private _navigate: NavigateFunction | undefined;
	@observable private _activeTab: TeamPageSection = TeamPageSection.Team;
	@observable private subscriptions$: IReactionDisposer[] = [];
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.EventsStore) private readonly _eventsStore: IEventsStore,
		@inject(Bindings.RacesStore) private _racesStore: IRacesStore,
		@inject(Bindings.HorsesStore) private _horsesStore: IHorsesStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._isLoading;
	}

	public getIsTabActive = (tab: TeamPageSection) => {
		return this._activeTab === tab;
	};

	public getIsTabActiveClass = (tab: TeamPageSection) => {
		return this.getIsTabActive(tab) ? "active" : "";
	};

	private get isLandingDisabled() {
		return this._settingsStore.settings?.hideLanding === true;
	}

	@action
	public setActiveTab = (event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
		const tab = event.currentTarget.dataset.tab as TeamPageSection;
		if (!tab) {
			return;
		}
		this._activeTab = tab;
	};

	dispose(): void {
		this.subscriptions$.forEach((dispose) => dispose());
	}

	init(param: IParams): void {
		this._navigate = param.navigate;
		const racesSub = reaction(
			() => this._racesStore.selectedRace?.id,
			() => this.fetchHorses(),
			{fireImmediately: true}
		);

		const teamSub = reaction(
			() => [this._racesStore.selectedRace?.id, this.isAuthorized],
			() => this.fetchTeam(),
			{fireImmediately: true}
		);

		const noRacesSub = reaction(
			() => [this._racesStore.list, this._horsesStore.list],
			() => this.checkRacesState(),
			{fireImmediately: true}
		);

		const landingSub = reaction(
			() => [this.isAuthorized, this.isLandingDisabled],
			() => this.checkLanding(),
			{fireImmediately: true}
		);

		this.subscriptions$.push(teamSub);
		this.subscriptions$.push(racesSub);
		this.subscriptions$.push(noRacesSub);
		this.subscriptions$.push(landingSub);
		void this._eventsStore.fetchEvents();
	}

	onChange(param: IParams): void {
		this._navigate = param.navigate;
	}

	private get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	private fetchHorses() {
		if (!this._racesStore.selectedRace?.id) {
			return;
		}

		void this._horsesStore.fetchHorses(this._racesStore.selectedRace.id);
	}

	private fetchTeam() {
		if (!this._racesStore.selectedRace || !this.isAuthorized) {
			return;
		}

		void this._teamStore.fetchDonkeyInsuranceCount();
		this._teamStore
			.fetchTeamForRace(this._racesStore.selectedRace.id)
			.catch((error: AxiosError<IApiResponse>) => {
				if (error.response?.status === NO_TEAM_CODE) {
					return;
				}
				this._modalsStore.showErrorModal(error);
			});
	}

	private checkRacesState() {
		const races = this._racesStore.list;
		const isRacesLoading = this._racesStore.isLoading;
		const horses = this._horsesStore.list;
		const isHorsesLoading = this._horsesStore.isLoading;

		if (!races.length && !isRacesLoading) {
			this._modalsStore.showModal(ModalType.NO_RACES);
		}

		// Check if horses exists for any of race
		if (races.length < 2 && !horses.length && !isHorsesLoading) {
			this._modalsStore.showModal(ModalType.NO_RACES);
		}
	}

	private checkLanding() {
		if (!this.isLandingDisabled && !this.isAuthorized) {
			this._navigate?.("/");
		}
	}
}
